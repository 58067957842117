<style>
  *{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
  }
  html,body,#app{
    margin:0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #f0c95c;
  }
  .frame{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    /*禁止页面上的文字被选中*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  ul,li{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  .clearfix:before,.clearfix:after {
    content: " ";
    display: table;
  }
  .clearfix:after {
    clear:both;
  }
  video{
    object-fit: fill !important;
  }
  .w-0{
    width: 0;
  }
  .h-0{
    height:0;
  }
  .index-2{
    z-index: 2;
  }
  .border-end-after-secondary::after{
		border-right-color: #6c757d!important;
	}
	.border-start-after-primary::after{
		border-left-color: #0d6efd!important;
	}
  .overflow-y-scroll{
		overflow-y: scroll;
	}
  .scroll-none::-webkit-scrollbar{
		display: none;
	}
  .disabled,[disabled]{
		pointer-events:unset !important;
		cursor: not-allowed !important;
	}
</style>
<template>
  <div class="frame" :style="screenStyle">
    <router-view/>
  </div>
</template>
<script>
import { useLayer } from "@/assets/js/useLayer.js"
import { LAYER_TYPE } from "@/assets/js/const.js"
import { nextTick } from '@vue/runtime-core';
  export default{
    data() {
      return {
        screenStyle: {
          width:'100%',
          height:'100%',
          fontSize:'16px'
        },
        ws:null,//全局使用的websocket对象
        wsMsgArr:new Array(),//websocket响应事件处理列表
        user:{
          name:"",
          guid:""
        },
        currentLine:{
            id:"",
            name:""
        },//当前选中线路对象
        startAudioList:[
          {
              url: require('@/assets/media/common/bet_start_1.mp3')
          },
          {
              url: require('@/assets/media/common/bet_start_2.mp3')
          },
          {
              url: require('@/assets/media/common/bet_start_3.mp3')
          },
          {
              url: require('@/assets/media/common/bet_start_4.mp3')
          },
          {
              url: require('@/assets/media/common/bet_start_5.mp3')
          },
          {
              url: require('@/assets/media/common/bet_start_6.mp3')
          },
      ],//开始音效
      endAudioList:[
          {
              url:require('@/assets/media/common/bet_stop.mp3')
          }
      ],//结束音效
      tenAudioList:[
          {
              url:require('@/assets/media/common/time_ten_1.mp3')
          },
          {
              url:require('@/assets/media/common/time_ten_2.mp3')
          },
          {
              url:require('@/assets/media/common/time_ten_3.mp3')
          }
      ],//最后10秒音效
      resultAudioList:[
          {
              url:require('@/assets/media/baccarat/win_draw.mp3')
          },
          {
              url:require('@/assets/media/baccarat/win_h.mp3')
          },
          {
              url:require('@/assets/media/baccarat/win_l.mp3')
          },
          {
              url:require('@/assets/media/baccarat/win_x.mp3')
          },
          {
              url:require('@/assets/media/baccarat/win_z.mp3')
          }
      ],//开结果音效
      }
    },
    methods: {
      /**设置显示区大小 */
      setScreen() {
        var scale=1.67;//内容区域的长宽比
        // var width=innerWidth/innerHeight>scale?innerHeight*scale+'px':'100%';
        var width='100%';
        var height=innerWidth/innerHeight<scale? innerWidth/scale+'px':'100%';
        var fontSize=(width=='100%'?innerWidth:width.replace('px',''))/45+'px';
        this.screenStyle={width,height,fontSize};
      },
      /**初始化websocket */
      initWebsocket(ws){
        this.ws = new WebSocket(ws);
        var lastMsg=[];//
        let invalid="";//无效字符串
        let timer;
        this.ws.onmessage=async (e) => {
            console.log(e.data)
            // var dataArr = [e.data];
            // if (e.data.indexOf('}{') != -1) {//判断是否有背向花括号，防止服务端推送连包
            //     dataArr = e.data.replace(/}{/ig, '}|{').split('|');
            // }else{//判断是否是Json类型，防止服务器推送断包
            //   if(e.data.indexOf("{")!=-1||e.data.indexOf("}")!=-1){//防止无关命令进入，如:ok1;ok2;等
            //     try {
            //       JSON.parse(e.data);
            //     } catch (error) {
            //       lastMsg.push(e.data);
            //       try {
            //         JSON.parse(lastMsg.join(""));
            //       } catch (error) {
            //         return;
            //       }
            //       dataArr=[lastMsg.join("")];
            //       lastMsg=[];
            //     }
            //   }
            // }
            //数据包处理
            let arr=[];//存放接收到的数据
            let str=e.data;
            try {
              let obj1 = JSON.parse(str);//尝试转换接收到的数据
              arr.push(obj1);//成功则加入数组作为一条有效数据
            } catch (error) {
              while (str.indexOf("}{")!=-1) {//连包
                console.log("有连包");
                let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                str = str.replace(part,"");
                try {
                  let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                  arr.push(obj2);//成功则加入数组作为一条有效数据
                } catch (error) {//失败说明是不完整数据
                  invalid+=part;//与上一次的无效数据拼一起
                  try {
                    let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                    arr.push(obj3);//成功则加入数组作为一条有效数据
                    invalid="";//并且清空无效字符串
                  } catch (error) {}//不成功不理会
                }
              }
              //走到这里说明没有连包了
              try {
                let obj4=JSON.parse(str);//尝试转换剩余的片段
                arr.push(obj4);//成功则加入数组作为一条有效数据
              } catch (error) {//失败则说明是不完整数据
                console.log("有分包");
                invalid+=str;//与上一次的无效数据拼一起
                try {
                  let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                  arr.push(obj5);//成功则加入数组作为一条有效数据
                  invalid="";//并且清空无效字符串
                } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
              }
            }
            //接口响应
            for (let i = 0; i < arr.length; i++){
              let data = arr[i];
              console.log("接收【转换】："+JSON.stringify(data));
              switch (data.command) {
                  case 'all_online':
                    timer&&clearInterval(timer);
                    timer = setInterval(()=>{
                        if(this.ws==null||this.ws.readyState!=1){
                            clearInterval(timer);
                            return;
                        }
                        let date = new Date();
                        console.log(date.getHours()+":"+date.getMinutes()+":"+date.getSeconds())
                        this.ws.wsSend('all_online', this.user.name, this.user.guid);
                    }, 6000)
                    break;
                  case 'all_otherlogin'://接收多方登录
                      useLayer({
                        str:"当前账号已在其他地方登录",
                        type:LAYER_TYPE.confirm,
                        btn:["好的"],
                        yesFn:()=>{
                          this.$router.push("/");
                        }
                      });
                      this.ws.close();
                      // this.removeSocketMesExcept();
                      break;
                  case 'all_logintop'://接收后台踢出
                      useLayer({
                        str:"账号被强制登出！",
                        type:LAYER_TYPE.confirm,
                        btn:["好的"],
                        yesFn:()=>{
                          this.$router.push("/");
                        }
                      });
                      this.ws.close();
                      break;
                  case 'all_getout'://接收超时登出
                      useLayer({
                        str:"检测到您长时间未使用，已为您登出，请重新登录！",
                        type:LAYER_TYPE.confirm,
                        btn:["好的"],
                        yesFn:()=>{
                          this.$router.push("/");
                        }
                      });
                      this.ws.close();
                      break;
                  // case 'all_online':
                  //     clearTimeout(this.lineTimer);
                  //     break;
              }
              for (var key in this.wsMsgArr) {
                  this.wsMsgArr[key](data);
              }
              await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
            }

            // for (var i = 0; i < dataArr.length; i++) {
            //     var data="";
            //     try {
            //         data = JSON.parse(dataArr[i]);
            //     } catch (error) {
            //         return;
            //     }
            //     if(e.data.indexOf("all_settlement")==-1&&e.data.indexOf("all_online")==-1&&
            //     e.data.indexOf("all_endgame")==-1&&e.data.indexOf("bac_receivepool")==-1){//无关命令过滤
            //       //console.log('接收数据：' + dataArr[i]);
            //     }
            //     if (data.error && data.error != '' && data.command != 'all_online') {
            //         useLayer({
            //           str:"服务端返回错误，请联系管理员！",
            //           type:LAYER_TYPE.confirm,
            //           btn:["好的"],
            //           yesFn:()=>{
            //             this.$router.push("/");
            //           }
            //         });
            //         throw new Error('命令' + data.command + '：返回错误' + data.error);
            //         return;
            //     }
            //     switch (data.command) {
            //         case 'all_otherlogin'://接收多方登录
            //             useLayer({
            //               str:"当前账号已在其他地方登录",
            //               type:LAYER_TYPE.confirm,
            //               btn:["好的"],
            //               yesFn:()=>{
            //                 this.$router.push("/");
            //               }
            //             });
            //             this.ws.close();
            //             // this.removeSocketMesExcept();
            //             break;
            //         case 'all_logintop'://接收后台踢出
            //             useLayer({
            //               str:"账号被强制登出！",
            //               type:LAYER_TYPE.confirm,
            //               btn:["好的"],
            //               yesFn:()=>{
            //                 this.$router.push("/");
            //               }
            //             });
            //             this.ws.close();
            //             break;
            //         case 'all_getout'://接收超时登出
            //             useLayer({
            //               str:"检测到您长时间未使用，已为您登出，请重新登录！",
            //               type:LAYER_TYPE.confirm,
            //               btn:["好的"],
            //               yesFn:()=>{
            //                 this.$router.push("/");
            //               }
            //             });
            //             this.ws.close();
            //             break;
            //         // case 'all_online':
            //         //     clearTimeout(this.lineTimer);
            //         //     break;
            //     }
            //     for (var key in this.wsMsgArr) {
            //         this.wsMsgArr[key](data);
            //     }
            // }
        }
      },
      /**
       * 添加websocket响应事件
       * @param {String} name
       * @param {Function} callback
       */
      addMessage(name,callback){
        (!this.wsMsgArr[name]) && (this.wsMsgArr[name] = callback,console.log("添加了"+name+"的websocket响应事件"));
      },
      /**
       * 删除websocket响应事件
       * @param {String[]} name
       */
      delMessage(){
        for (var i = 0; i < arguments.length; i++) {
            this.wsMsgArr[arguments[i]] && (delete this.wsMsgArr[arguments[i]],console.log("移除了"+arguments[i]+"的websocket响应事件"));
        }
      }
    },
    //页面初始化完成
    mounted(){
      this.setScreen();
      addEventListener("resize",() => {
        this.setScreen();
      })
    }
  }
</script>
