<style scoped>
    .page_prev,.page_next{
        display: block;
        position: relative;
        top: 50%;
        float: left;
        cursor: pointer;
    }
    .page_prev{
        transform: translate(0,-50%);
    }
    .page_next{
        transform: translate(0,-50%) rotateY(180deg);
    }
    .list-content{
        background: #000;
        width: 95%;
        height: 100%;
        float: left;
        border-radius: .2em;
        padding: .3%;
    }
    .list-content>li{
        border-radius: 15%;
        width: 4.3%;
        height: 100%;
        float: left;
        margin: 0 .12%;
        font-size: .4em;
        text-align: center;
        line-height: 100%;
        padding: .2% 0;
        overflow: hidden;
        color: #0a281e;
        cursor: pointer;
    }
    .list-content>li.bai{
        background: radial-gradient(5em at 25% 0%,#fdfbd4 0%,#fad588 45%,#fcc678);
    }
    .list-content>li.active{
        background: radial-gradient(5em at 25% 0%,#dcf6e2 0%,#35a87d 45%,#078763);
    }
    .list-content>li.long{
        background: radial-gradient(5em at 25% 0%,#fdf8bd 0%,#f6c400 45%,#f07a15);
    }
    .table-pearl{
        background: #fff;
        width: 70%;
        height: 300%;
        position: absolute;
        left:15%;
        top:100%;
        z-index: 1;
        box-shadow: .1em .1em .2em 0px black;
    }
    .table-pearl>span{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color:#666;
        font-size: 3.5em;
    }
</style>
<template>
    <div class="clearfix" style="display:flex">
        <img class="page_prev" src="@/assets/images/page_prev.png" alt="" @click="changePage(-1)">
        <ul class="list-content" style="flex-grow:1">
            <li v-for="item in tableArr.slice(22*(page-1),22*page)" @mouseenter="showPearl(true,item.id)" @mouseleave="showPearl(false)" @click="changeTable(item.id,item.game)" :key="item" :class="tableId==item.id?'active':(item.game==1?'bai':'long')">{{item.room+item.table}}</li>
        </ul>
        <img class="page_next" src="@/assets/images/page_prev.png" alt="" @click="changePage(1)">
        <div class="table-pearl" v-show="isShowPearl">
            <canvas class="hover-canvas"></canvas>
            <span>{{countDown}}</span>
        </div>
    </div>
</template>
<script>
    import {drawCanvas,fillRoomPearl,delPearlTie} from '@/assets/js/common.js'
    export default {
        props: ["tableArr","tableId"],
        data() {
            return {
                page:1,//当前页数
                isShowPearl:false,//是否显示路珠
                tablePearlArr:[],//台面路珠
                countDown:0,//倒计时
                timer:null,//倒计时计时器对象
                showId:0,//移上去显示的台面ID
            }
        },
        mounted(){
            console.log("加载条状台面列表")
            this.$root.addMessage("tableListLine",(data) => {
                switch (data.command) {
                    case "all_getroompearl"://获取台面路珠
                        if(data.useto=="list"){
                            drawCanvas(document.querySelectorAll(".hover-canvas"),36,6);
                            var arr=JSON.parse(JSON.stringify(data.pearl));
                            fillRoomPearl(document.querySelector(".hover-canvas"),6,36,delPearlTie(arr.reverse(),36).reverse());
                        }
                        break;
                    case "all_getcountdown"://获取倒计时
                        this.setCountDown(data.countdown);
                    case "bac_gameresult"://接收游戏结果
                        if(data.id==this.showId){
                            this.$root.ws.wsSend("all_getroompearl", 1, data.id, 36*2, 'list', this.$root.user.name, this.$root.user.guid);//乘2是避免有和的情况导致少列
                        }
                        break;
                    case "all_startgame"://接收游戏开局
                        if(data.id==this.showId){
                            this.setCountDown(data.countdown);
                        }
                        break;
                    default:
                        break;
                }
            })
        },
        methods: {
            /**换页 */
            changePage(type) {
                if((type==1&&this.page<Math.ceil(this.tableArr.length/22))||(type==-1&&this.page>1)){
                    this.page+=type;
                }
            },
            /**切换台面 */
            changeTable(id,game){
                this.$emit("change-game",game);
                this.$root.ws.wsSend("all_entertable",game,id,this.$root.user.name,this.$root.user.guid);
            },
            /**显示路珠 */
            showPearl(isShow,id){
                if(isShow){
                    document.querySelector(".hover-canvas").setAttribute("width",document.querySelector(".table-list-line").clientWidth*.7);
                    document.querySelector(".hover-canvas").setAttribute("height",document.querySelector(".table-list-line").clientHeight*3);
                    this.$root.ws.wsSend("all_getcountdown",1,id,this.$root.user.name,this.$root.user.guid);
                    this.$root.ws.wsSend('all_getroompearl', 1, id, 36*2, 'list', this.$root.user.name, this.$root.user.guid);//乘2是避免有和的情况导致少列
                    this.isShowPearl=true;
                    this.showId=id;
                }else{
                    this.isShowPearl=false;
                    this.showId=0;
                }
            },
            /**设置倒计时 */
            setCountDown(countDown){
                this.timer&&clearInterval(this.timer);
                this.countDown=countDown;
                var _this=this;
                this.timer = setInterval(function setTime(){
                    if(_this.countDown==0){
                        clearInterval(_this.timer);
                    }else{
                        _this.countDown--;
                    }
                    return setTime;
                }(),1000)
            }
        },
    }
</script>