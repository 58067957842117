import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import Table from '../views/Table.vue'
import PhoneTable from '../views/PhoneTable.vue'

const routes = [
  {
    path:'/',
    redirect:'/login'
  },
  {
    name:'login',
    path: '/login/:quickUserInfo*',
    component: Login,
    props:true
  },
  {
    path:'/index',
    name:'index',
    component:Index
  },
  {
    path:'/table',
    name:'table',
    component:Table
  },
  {
    path:'/share/:encrystr',
    name:'phoneTable',
    component:PhoneTable,
    props:true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
