<style scoped>
.login-frame{
    height: 100%;
    position: relative;
}
.rail{
    height: 8.8%;
    width: 100%;
    background: url("../assets/images/rail.jpg") repeat-x;
    background-size: contain;
}
.content{
    overflow: hidden;
    height: 82.4%;
    background: url("../assets/images/bg.jpg");
}
.lantern-light{
    position: absolute;
    top: 13%;
    border-radius: 50%;
    box-shadow: -5px 5px 50px 4px #fffb00;
    perspective: 1000px;
    /* width: 14.003%;
    height: 17.54%; */
    width: 13%;
    height: 16%;
}
.lantern-light.left-light{
    left: 3%;
}
.lantern-light.right-light{
    right: 3%;
}
.lantern-light::before {
    top: -8.5%;
    border-top-left-radius: .3em;
    border-top-right-radius: .3em;
}
.lantern-light::after {
    bottom: -8.5%;
    border-bottom-left-radius: .3em;
    border-bottom-right-radius: .3em;
}
.lantern-light::before, .lantern-light::after {
    content: "";
    position: absolute;
    border: .05em solid #fffb00;
    width: 50%;
    height: 13.3333%;
    background: linear-gradient(to right,#fffb00,#ffa500,#fffb00,#ffa500,#fffb00);
    left: 25%;
    z-index: 1;
}
.lantern-line{
    background: #d90a00;
    border: .1em solid #ff0b00;
    border-top: 0;
    border-bottom: 0;
    width: 4%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
}
.lantern-top{
    top: -27%;
    height: 18%;
}
.circle-perspective{
    height: 100%;
    transform-style:preserve-3d;
    transform: rotateY(15deg);
}
.lantern-circle{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: .13em solid #d6c100;
    background: #ff1207;
}
.lantern-foot{
    margin-top: 5%;
    height: 80%;
    position: relative;
}
.lantern-bottom{
    top: 0;
    height: 50%;
}
.lantern-line-pearl{
    background: #fffb00;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    top: 10%;
}
.lantern-line-tail{
    background: #fffb00;
    width: 8%;
    height: 80%;
    border-top-left-radius: 28%;
    border-top-right-radius: 28%;
    border-bottom-right-radius: .2em;
    border-bottom-left-radius: .2em;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    top: 22%;
    overflow: hidden;
}
.lantern-line-cord-first{
    background: #ff1207;
    height: 5%;
    margin-top: 60%;
}
.lantern-line-cord-second{
    background: #ff1207;
    height: 15%;
    margin-top: 25%;
}
.ticker{
    width: 100%;
    height: 12%;
    position: absolute;
    background:url("../assets/images/ticker.png") no-repeat;
    background-size: cover;
    top: 8%;
}
.login-box{
    border:.11em solid #ffff00;
    background: rgba(247, 0, 0, 0.3);
}
.login-box-title{
    border:.11em solid #ffff00;
    border-bottom: 0;
    background: linear-gradient(0deg,#ab0000 0%,#cb0000 50%,#ab0000 100%);
    text-align: center;
    border-top-left-radius: .3em;
    border-top-right-radius: .3em;
}
.login-box-title>span{
    font-family: '华文琥珀';
    background: linear-gradient(0deg,#fa7205 0%,#ffff00 50%,#fa7205 100%);
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -ms-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: .8em;
}
.login-box-inner{
    border: .1em solid #ffff00;
    border-radius: .3em;
    font-size: .8em;
}
.login-lbl{
    font-family: '华文琥珀';
    background: linear-gradient(0deg,#fa7205 0%,#ffff00 50%,#fa7205 100%);
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -ms-background-clip: text;
    background-clip: text;
    color: transparent;
    vertical-align: middle;
}
.login-txt{
    font-size: .88em;
    display: inline-block;
    vertical-align: middle;
    border:.11em solid transparent;
    border-image: linear-gradient(0deg,#fa7205 0%,#ffff00 50%,#fa7205 100%) 1 1;
}
.login-box-inner>div:not(.login-btn-box){
    max-height: 15%;
    height: 13%;
    /* overflow: hidden; */
}
.login-btn-box{
    width: 50%;
    height: 18%;
    background: linear-gradient(0deg,#c88f43 0%,#f6e384 50%,#faf4cb 90%,#c57f24 100%);
    border-radius: .3em;
    overflow: hidden;
}
.login-btn{
    margin: 3% auto;
    display: block;
    width: 95%;
    height: 85%;
    font-size: 1em;
    color: #cc0000;
    font-family: '微软雅黑';
    font-weight: bold;
    background: linear-gradient(0deg,#ffda12 0%,#ff8e04 50%,#ffda12 100%);
    border: 0;
    border-radius: .2em;
    cursor: pointer;
}
.login-img{
    display: inline-block;
    vertical-align: middle;
    border:1px solid #ccc;
    border-radius: .2em;
    cursor: pointer;
    background: #fff;
}
.signboard{
    width: 300px;
    height: 300px;
    top: 30%;
    left: 18%;
    border-radius: 1em;
    background: linear-gradient(135deg,#a47310 0%,#fcd891 50%,#a47310 60%,#fcd891 100%);
    transform: rotate(45deg);
    padding: .6%;
}
.signboard-second{
    width: 100%;
    height: 100%;
    border-radius: .8em;
    border:.05em solid #f9ed25;
    background: linear-gradient(135deg,#dd982e 0%,#f7e78c 48%,#996701 50%,#f5e488 80%);
    padding: 1.6%;
}
.signboard-third{
    width: 100%;
    height: 100%;
    border-radius: .6em;
    background: linear-gradient(135deg,#6f0000 0%,#c80000 25%,#6f0000 50%,#c80000 75%,#6f0000 100%);
    padding: 3%;
}
.signboard-last{
    width: 100%;
    height: 100%;
    border-radius: .4em;
    background: radial-gradient(6em at 30% 30%,#f75454 10%,#980202);
}
.signboard-img{
    width: 100%;
    position: absolute;
    top: 44%;
    transform: translate(-6%,-50%) rotateZ(-45deg);
    left: 0;
}
.login-line input{
    vertical-align: middle;
}
.login-line label{
    font-size: .6em;
    color: #ffff00;
    vertical-align: middle;
}

/* lady */
</style>
<style>
    /* 动画特效，放在scoped里无法被执行 */
    .circle-perspective{
        animation: abc 5s infinite linear;
        -webkit-animation:abc 5s infinite linear;
        -moz-animation:abc 5s infinite linear;
        -o-animation:abc 5s infinite linear;
    }
    @keyframes abc{
        from {
            transform: rotateY(15deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
    @-webkit-keyframes abc{
        from {
            transform: rotateY(15deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
    @media (max-width: 992px){
        .signboard[data-v-26084dc2] {
            width: 230px;
            height: 230px;
            }
    }
    @media (max-width: 768px){
        .signboard[data-v-26084dc2] {
            width: 200px;
            height: 200px;
            }
        }
    @media (max-width: 576px){
        .signboard[data-v-26084dc2] {
            width: 150px;
            height: 150px;
            }
    }

</style>
<template>
    <div class="login-frame">
        <div class="rail"></div>
        <div class="content">
            <!-- 彩带 -->
            <div class="ticker"></div>
            <!-- 灯笼 -->
            <div v-for="i in 2" :key="i" class="lantern-light" :class="i==1?'left-light':'right-light'">
                <div class="lantern-line lantern-top"></div>
                <div class="circle-perspective">
                    <div class="lantern-circle" v-for="j in 12" :key="j" :style="{transform:'rotateY('+30*j+'deg)'}"></div>
                </div>
                <div class="lantern-foot">
                    <div class="lantern-line lantern-bottom"></div>
                    <div class="lantern-line-pearl"></div>
                    <div class="lantern-line-pearl"></div>
                    <div class="lantern-line-tail">
                        <div class="lantern-line-cord-first"></div>
                        <div class="lantern-line-cord-second"></div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-around align-items-center h-100">
                <!-- 图标 -->
                <div class="signboard">
                    <div class="signboard-second">
                        <div class="signboard-third">
                            <div class="signboard-last">
                                <img class="signboard-img" src="signboard.png">
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- 登录框 -->
                <div class="d-flex flex-column align-items-center">
                    <div class="login-box-title px-3">
                        <span>电话投注</span>
                    </div>
                    <div class="login-box px-3 py-4 rounded" @keyup.enter="login">
                        <div class="login-box-inner px-3 py-4 d-flex flex-column">
                            <div class="d-flex">
                                <div class="d-flex flex-column">
                                    <label for="txtUser" class="login-lbl mb-3">用&nbsp;户&nbsp;名：</label>
                                    <label for="txtPwd" class="login-lbl mb-3">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码：</label>
                                    <label for="txtCode" class="login-lbl mb-3">验&nbsp;证&nbsp;码：</label>
                                </div>
                                <div class="d-flex flex-column" style="width:8em">
                                    <input type="text" id="txtUser" class="login-txt w-100 mb-3" v-model="name">
                                    <input type="password" id="txtPwd" class="login-txt w-100 mb-3" v-model="pwd">
                                    <div class="d-flex mb-3">
                                        <div class="col-7">
                                            <input type="text" id="txtCode" class="login-txt w-100 flex-grow-1" v-model="code">
                                        </div>
                                        <div class="col-5 d-flex flex-column ps-2">
                                            <canvas id="loginCode" class="login-img w-100 h-0 flex-grow-1" @click="changeCode"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="login-line d-flex mb-1">
                                <div class="d-flex flex-wrap flex-grow-1 w-0">
                                    <div class="d-flex me-2 mb-2" v-for="(key,i,num) in ws" :key="key">
                                        <input type="radio" name="wsline" :id="i" :value="key" v-model="wsLine" :checked="num==0?true:false" >
                                        <label :for="i">{{i}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="login-btn-box">
                                    <button class="login-btn" type="button" @click="login">登 录</button>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-link" @click="linkToChat">
                                    <i class="bi bi-people me-1"></i>
                                    <span>在线客服</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rail"></div>
    </div>
</template>
<script>
    import {drawCode,getDeviceType,getIP} from '@/assets/js/common.js'
    import {useLayer} from "@/assets/js/useLayer.js"
    export default{
        props:{
            quickUserInfo:{
                default:[]
            },
        },
        mounted(){
            this.codeCreate = drawCode(document.getElementById("loginCode"));
        },
        data() {
            return {
                //name: "",//用户名
                //pwd:"",//密码
                name: this.quickUserInfo[0]||"",//用户名
                pwd:this.quickUserInfo[1]||"",//密码
                tableName:this.quickUserInfo[2]||"",//台号
                code:"",//验证码
                codeCreate:"",//生成的验证码
                ws:config().telws,
                wsLine:Object.values(config().telws)[0],//选中ws线路
            }
        },
        methods: {
            login() {
                if(this.name==""){
                    useLayer({str:"请输入用户名"});
                }else if(this.pwd==""){
                    useLayer({str:"请输入密码"});
                }else if(this.code==""){
                    useLayer({str:"请输入验证码"});
                }else if(this.codeCreate!=this.code){
                    useLayer({str:"验证码错误"});
                }else if(this.wsLine==""){
                    useLayer({str:"请选择线路"});
                }else{
                    this.$root.addMessage("login",(data) => {
                        switch (data.command) {
                            case "tel_login"://登录
                                if(data.result==-1||data.result==-2){
                                    useLayer({str:"用户名或密码错误"});
                                    return;
                                }else if(data.result==1){

                                    this.$root.ws.wsSend('all_online', this.name, data.guid);
                                    // var timer = setInterval(()=>{
                                    //     if(this.$root.ws==null||this.$root.ws.readyState!=1){
                                    //         clearInterval(timer);
                                    //         return;
                                    //     }
                                    //     let date = new Date();
                                    //     console.log(date.getHours()+":"+date.getMinutes()+":"+date.getSeconds())
                                    // }, 6000)
                                    this.$root.delMessage("login");
                                    this.$root.user.name=this.name;
                                    this.$root.user.guid=data.guid;
                                    this.$router.push({
                                        name:"index",
                                        params:{tableName:this.tableName}
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    });
                    this.$root.initWebsocket(this.wsLine);//this.$root为获取App.vue中的对象
                    this.$root.ws.onopen=() => {
                        this.$root.ws.wsSend("tel_login",this.name,this.pwd,getDeviceType(),getIP());//发送登录
                    }
                }
            },
            changeCode(){
                this.codeCreate = drawCode(document.getElementById("loginCode"));
            },
            linkToChat(){
                window.open(config().chatUrl);
            }
        },
        watch: {
            /**
             * 监听快速登录信息变化
             */
             quickUserInfo:{
                handler(val){
                    if(val){
                        val[0]&&(this.name=val[0]);
                        val[1]&&(this.pwd=val[1]);
                        val[2]&&(this.tableName=val[2]);
                        this.$nextTick(()=>{
                            this.code=this.codeCreate;
                            this.login();
                        })
                    }
                },
                immediate:true,
             }
            
        },
    }
</script>