<style scoped>
    .table-list-frame{
        width: 100%;
        height: 87%;
        overflow: hidden;
    }
    .table-list-item{
        width: 32%;
        height: 32%;
        float: left;
        margin: .5% 0 0 1%;
    }
    .table-list-title{
        background: linear-gradient(0deg,#6a6a6a 0%,#b4b4b4 100%);
        height: 18%;
        border-top-left-radius: .3em;
        border-top-right-radius: .3em;
        position: relative;
    }
    .table-list-title>span{
        color: #fff;
        font-size: .5em;
        display: block;
        float: right;
        margin-right: 3%;
    }
    .table-list-title>button{
        float: right;
        font-size: .5em;
        padding: 0 1%;
        color: #000;
        border:.1em solid #000;
        cursor: pointer;
        border-radius: .2em;
        background: linear-gradient(0deg,#9f6a2d 0%,#eec961 30%,#eec961 70%,#9f6a2d 100%);
        font-weight: bold;
        margin: .6% 1% 0 0;
        line-height: 100%;
    }
    .table-list-title>button:hover{
        border-color: #ffff00;
    }
    .table-list-name{
        position: absolute;
        background: linear-gradient(0deg,#000 0%,#898781 100%);
        width:35%;
        height: 120%;
        top: -20%;
        border-top-left-radius: .3em;
        font-size: .5em;
        white-space: nowrap;
        color: #ffff00;
        text-align: center;
        line-height: 2em;
    }
    .bevel{
        position: absolute;
        overflow: hidden;
        height: 120%;
        width: 10%;
        top: -20%;
        left: 33%;
    }
    .bevel>div{
        width: 55%;
        height: 122%;
        background: linear-gradient(30deg,#000 25%,#898781 100%);
        transform: rotate3d(0,0,1,-30deg);
        margin-top: 4%;
        border-top-right-radius: .15em;
    }
    .table-list-content{
        background: #000;
        height: 81%;
        padding-top: 1%;
        position: relative;
    }
    .table-list-content>canvas{
        background: #fff;
    }
    .table-list-content>span{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: default;
        font-size: 2.6em;
        transform: translate(-50%,-50%);
        color: #858585;
    }
    .pagination{
        position: relative;
        width: 50%;
        height: 7.5%;
        border-radius: 1em;
        background: #2a1c00;
        margin: 0 auto;
        top: 3%;
    }
</style>
<template>
    <div>
        <div class="table-list-frame clearfix">
            <div v-for="item of tableArr.slice(9*(currentPage-1),9*currentPage)" class="table-list-item" :key="item">
                <div class="table-list-title clearfix">
                    <div class="bevel">
                        <div></div>
                    </div>
                    <div class="table-list-name">{{item.room+item.table}}</div>
                    <button type="button" @click="enterTable(item.id,item.game)">进入游戏</button>
                    <span>
                        <span>{{item.innings}}</span>局
                    </span>
                    <span>
                        <span>{{item.boot}}</span>靴
                    </span>
                </div>
                <div class="table-list-content">
                    <canvas :width="canvasWidth" :height="canvasHeight" :table-id="item.id"></canvas>
                    <span v-count-down="item.countdown">0</span>
                </div>
            </div>
        </div>
        <pagination class="pagination" :current-page="currentPage" :count-page="countPage" @change-page="changePage"></pagination>
    </div>
</template>
<script>
    import Pagination from '@/components/private/Pagination.vue'
    import { drawCanvas,fillRoomPearl,delPearlTie } from '@/assets/js/common.js';
    export default{
        directives: {
            'count-down':{//倒计时指令
                mounted(elem,binding){
                    var timer = setInterval(function setTime(){
                        if(binding.value==0){
                            clearInterval(timer);
                            elem.innerText = 0;
                        }else{
                            elem.innerText=binding.value--;
                        }
                        return setTime;
                    }(),1000)
                }
            }
        },
        props:["currentLine","tableName"],
        data() {
            return {
                canvasWidth: 0,//路珠画布宽
                canvasHeight:0,//路珠画布高
                tablePearlArr:[],//所有台面的路珠列表
                currentPage:1,//当前页数
                countPage:0,//总页数
                tableArr:[],//台面列表
                timeArr:[],//当前台面列表计时秒数
                game:0,//当前游戏
                tableId:"",
            }
        },
        mounted(){
            //this.tableId&&this.enterTable(this.tableId,1);
            this.$root.addMessage("tableList",(data) => {
                switch (data.command) {
                    case "all_getroomlist"://获取台面列表
                        this.tableArr=data.list;

                        if(this.tableName){
                            let item = this.tableArr.find(v=>v.table==this.tableName);
                            if(item&&item.id){
                                this.enterTable(item.id,item.game);
                            }else{
                                this.$router.push('/')
                            }
                        }
                        this.$nextTick(() => {
                            this.canvasWidth=document.querySelector(".table-list-content").clientWidth;
                            this.canvasHeight=document.querySelector(".table-list-content").clientHeight;
                        })
                        var arr=data.list.slice(9*(this.currentPage-1),9*this.currentPage);
                        for (let i = 0; i < arr.length; i++) {
                            this.$root.ws.wsSend('all_getroompearl', 1, arr[i].id, 19*2, 'list', this.$root.user.name, this.$root.user.guid);//乘2是避免有和的情况导致少列
                        }
                        // addEventListener("resize",() => {
                        //     this.canvasWidth=document.querySelector(".table-list-content").clientWidth;
                        //     this.canvasHeight=document.querySelector(".table-list-content").clientHeight;
                        //     drawCanvas(document.querySelectorAll("canvas"),19,6);//绘制表格
                        //     for (let i in this.tablePearlArr) {
                        //         fillRoomPearl(document.querySelector("[table-id='"+this.tablePearlArr[i].id+"']"),6,19,delPearlTie(this.tablePearlArr[i].pearl,19));
                        //     }
                        // })
                        break;
                    case "all_getroompearl"://获取台面路珠
                        var isHas=false;//是否已存在
                        for (let i=0;i<this.tablePearlArr.length;i++) {
                            if (this.tablePearlArr[i].id==data.id) {
                                isHas=true;
                                break;
                            }
                        }
                        if(!isHas){//不存在
                            this.tablePearlArr.push({
                                id:data.id,
                                pearl:data.pearl
                            });
                        }
                        drawCanvas(document.querySelectorAll("[table-id='"+data.id+"']"),19,6);//
                        var arr=JSON.parse(JSON.stringify(data.pearl));//深克隆数组
                        fillRoomPearl(document.querySelector("[table-id='"+data.id+"']"),6,19,delPearlTie(arr.reverse(),19).reverse());
                        break;
                    case "all_entertable"://进入台面
                        if(data.result==1){
                            this.$root.delMessage("index","navbar","tableList","videoPlayervideo1","videoPlayervideo2");
                            this.$router.push({
                                path:"/Table",
                                name:"table",
                                params:{
                                    tableInfo:JSON.stringify({
                                        boot:data.boot,
                                        innings:data.innings,
                                        room:data.room,
                                        table:data.table,
                                        id:data.id,
                                        game:this.game
                                    }),
                                    tableArr:JSON.stringify({tableArr:this.tableArr}),
                                    currentLine:JSON.stringify(this.currentLine),
                                }
                            });
                        }
                        break;
                    case "all_startgame"://接收游戏开局
                        for (let i=0;i<this.tableArr.length;i++) {
                            var obj={
                                boot:data.boot,
                                countdown:data.countdown,
                                game:this.tableArr[i].game,
                                id:data.id,
                                innings:data.innings,
                                room:this.tableArr[i].room,
                                table:this.tableArr[i].table
                            }
                            if (this.tableArr[i].id==data.id) {
                                this.tableArr[i]=obj;
                                this.$nextTick(() => {
                                    drawCanvas(document.querySelectorAll("[table-id='"+data.id+"']"),19,6);
                                    for (const key in this.tablePearlArr) {
                                        if (this.tablePearlArr[key].id==data.id) {
                                            var arr=JSON.parse(JSON.stringify(this.tablePearlArr[key].pearl));
                                            fillRoomPearl(document.querySelector("[table-id='"+data.id+"']"),6,19,delPearlTie(arr.reverse(),19).reverse());
                                        }
                                    }
                                })
                                break;
                            }
                            //else if(i==this.tableArr.length-1){//匹配到最后一项都没有id一致的项，说明是新的台面
                                // this.tableArr.push(obj);
                                // this.$nextTick(() => {
                                //     drawCanvas(document.querySelectorAll("[table-id='"+data.id+"']"),19,6);
                                //     for (const key in this.tablePearlArr) {
                                //         if (this.tablePearlArr[key].id==data.id) {
                                //             var arr=JSON.parse(JSON.stringify(this.tablePearlArr[key].pearl));
                                //             fillRoomPearl(document.querySelector("[table-id='"+data.id+"']"),6,19,delPearlTie(arr.reverse(),19).reverse());
                                //         }
                                //     }
                                // })
                            //    this.$root.ws&&this.$root.ws.wsSend("all_getroomlist",1,this.$root.user.name,this.$root.user.guid);
                            //}
                        }
                        break;
                    case "bac_gameresult"://接收游戏结果
                        var arrZ = ['D', 'M', 'F', 'E', 'A'];
                        var arrX = ['H', 'N', 'G', 'I', 'B'];
                        var arrH = ['L', 'J', 'K', 'O', 'C'];
                        for (let i=0;i<this.tablePearlArr.length;i++) {
                            if (this.tablePearlArr[i].id==data.id) {
                                if (this.tablePearlArr[i].pearl && this.tablePearlArr[i].pearl.length > 0 && (
                                    (arrZ.indexOf(data.pearl.substr(0, 1)) != -1 && arrZ.indexOf(this.tablePearlArr[i].pearl[this.tablePearlArr[i].pearl.length - 1][0].substr(0, 1)) != -1) ||
                                    (arrX.indexOf(data.pearl.substr(0, 1)) != -1 && arrX.indexOf(this.tablePearlArr[i].pearl[this.tablePearlArr[i].pearl.length - 1][0].substr(0, 1)) != -1) ||
                                    (arrH.indexOf(data.pearl.substr(0, 1)) != -1 && arrH.indexOf(this.tablePearlArr[i].pearl[this.tablePearlArr[i].pearl.length - 1][0].substr(0, 1)) != -1)
                                    )) {//如果当前的露珠类型与上一项同类，则加到上一项的队列中
                                    this.tablePearlArr[i].pearl[this.tablePearlArr[i].pearl.length - 1].push(data.pearl);
                                } else {//否则新建自己的队列
                                    this.tablePearlArr[i].pearl.push([data.pearl]);
                                }
                                drawCanvas(document.querySelectorAll("[table-id='"+data.id+"']"),19,6);
                                var arr=JSON.parse(JSON.stringify(this.tablePearlArr[i].pearl));
                                fillRoomPearl(document.querySelector("[table-id='"+data.id+"']"),6,19,delPearlTie(arr.reverse(),19).reverse());
                                break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            })
            this.$root.ws&&this.$root.ws.wsSend("all_getroomlist",1,this.$root.user.name,this.$root.user.guid);
        },
        unmounted() {
            this.$root.delMessage('tableList');
        },
        updated(){
            // var MutationObserver=window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            // var observer=new MutationObserver((mutationList) => {
            //     console.dir(mutationList)
            //     for (let mutation of mutationList) {
            //         console.log(mutation)
            //     }
            // })
            // observer.observe(document.querySelector(".table-list-content"),{attributes:true,attributeFilter:['style'],attributeOldValue:true})
        },
        components:{
            Pagination
        },
        methods: {
            enterTable(id,game) {
                this.game=game;
                this.$root.ws.wsSend("all_entertable",game,id,this.$root.user.name,this.$root.user.guid);
            },
            changePage(type){
                this.$root.ws.wsSend("all_getroomlist",1,this.$root.user.name,this.$root.user.guid);
                switch (type) {
                    case -1://上一页
                        if(this.currentPage==1){return;}
                        this.currentPage>1&&this.currentPage--;
                        break;
                    case 1://下一页
                        if(this.currentPage==this.countPage){return;}
                        this.currentPage<this.countPage&&this.currentPage++;
                        break;
                    case 0://首页
                        if(this.currentPage==1){return;}
                        this.currentPage=1;
                        break;
                    case 2://尾页
                        if(this.currentPage==this.countPage){return;}
                        this.currentPage=this.countPage;
                        break;
                    default:
                        break;
                }
            }
        },
        watch: {
            tableArr(newValue){
                this.countPage=Math.ceil(newValue.length/9);
            },
        },
    }
</script>