<style scoped src="../assets/css/table.css"></style>
<template>
    <div v-if="$root.ws" class="table-frame">
        <input id="stateInfo" type="hidden" :value="stateInfo" />
        <div class="player-container clearfix">
            <video-player class="video-player" video-id="video1" :video-url="videoUrlOne"></video-player>
            <video-player class="video-player" video-id="video2" :video-url="videoUrlTwo"></video-player>
            <div class="table-info" style="display:flex;flex-direction:column;justify-content:space-between;">
                <img class="table-info-logo" src="logo.png" alt="">
                <div class="table-info-title">账号</div>
                <div class="table-info-text">{{$root.user.name}}</div>
                <div class="table-info-title">靴局</div>
                <div class="table-info-text">{{tableInfo.boot}}-{{tableInfo.innings}}</div>
                <div class="table-info-time">{{countDown}}</div>
                <div class="table-info-table">
                    <span></span>
                    <div class="table-info-text">{{tableInfo.table}}</div>
                    <span></span>
                </div>
            </div>
            <div class="video-tool clearfix">
                <div class="video-tool-player">
                    <span @click="btnScreenClick">{{screen}}</span>
                    <!-- <span @click="btnVideo1Click">{{video1}}</span>
                    <span @click="btnVideo2Click">{{video2}}</span> -->
                </div>
                <div class="video-tool-table" @click="showTableBlock">显示全部台面</div>
            </div>
        </div>
        <table-list-block class="table-list-block" :is-show="isShowBlock" :table-arr="tableArr" :table-id="tableInfo.id" @change-game="changeGame"></table-list-block>
        <div class="table-red">
            <span class="table-red-title">台&nbsp;&nbsp;&nbsp;红</span>
            <span v-show="!(tableInfo.game==3&&(i=='zd'||i=='xd'))" v-for="(item,i) in limitred" :key="(item,i)" class="table-red-content">
                <span class="table-red-type">{{i=="z"?(tableInfo.game==1?"庄":"龙"):(i=="x"?(tableInfo.game==1?"闲":"虎"):(i=="h"?"和":(i=="zd"?"庄对":"闲对")))}}</span>
                <span class="table-red-val">{{item}}</span>
            </span>
        </div>
        <div class="table-option d-flex">
            <span class="notice-title text-nowrap">系统公告</span>
            <marquee class="notice-content flex-grow-1" behavior="" direction="">{{notice}}</marquee>
            <button class="table-option-btn" type="button" @click="goLobby">返回大厅</button>
            <button class="table-option-btn" type="button" @click="exitGame">退出游戏</button>
            <button class="table-option-btn" type="button" @click="isShowQuestion=!isShowQuestion">反馈问题</button>
            <button class="table-option-btn" type="button" @click="!isShowChat&&(isShowChat=true)">联系客服</button>
        </div>
        <table-list-line class="table-list-line" :table-arr="tableArr" :table-id="tableInfo.id" @change-game="changeGame"></table-list-line>
        <pearl-history class="pearl-history" :table-id="tableInfo.id" :game="tableInfo.game"></pearl-history>
        <!-- 反馈问题 -->
        <div v-show="isShowQuestion" style="position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index: 2;">
            <div style="background: linear-gradient(0deg,#000 0%,#898781 100%);color:white;font-size: .7em;border-top-left-radius: .2em;border-top-right-radius: .2em;line-height: 2;padding-left: .5em;">反馈问题</div>
            <div style="background: linear-gradient(0deg,#7c3f18 0%,#e6be59 40%,#f7e89e 80%,#976648 100%);padding:.4em">
                <div style="font-size:.55em">
                    <div style="display:flex;margin-bottom:.2em;">
                        <label for="title">标题：</label>
                        <div style="flex-grow:1;">
                            <input v-model="questionTitle" style="width:100%" id="title" type="text" placeholder="标题">
                        </div>
                    </div>
                    <div style="display:flex;margin-bottom:.2em;">
                        <label style="white-space: nowrap;" for="content">内容：</label>
                        <div style="flex-grow:1;">
                            <textarea v-model="questionContent" style="display:block" id="content" cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>
                <div style="display:flex;justify-content:center;">
                    <button type="button" @click="subQuestion" style="background: linear-gradient(0deg,#7c3f18 0%,#e6be59 40%,#f7e89e 80%,#976648 100%);display:block;margin-right:.5em">提交</button>
                    <button type="button" @click="isShowQuestion=!isShowQuestion" style="background: linear-gradient(0deg,#7c3f18 0%,#e6be59 40%,#f7e89e 80%,#976648 100%);display:block;">关闭</button>
                </div>
            </div>
        </div>
        <!-- 音效 -->
        <audio id="audioId" autoplay="autoplay" :src="audioSrc"></audio>
        <!-- 联系客服 -->
        <chat v-if="isLoadChat" v-show="isShowChat" class="position-absolute start-0 end-0 top-0 index-2 fs-5" @close-chat="isShowChat=false" @msg-websocket="msgWebsocket" @send-websocket="sendWebsocket"></chat>
    </div>
</template>
<script>
    import VideoPlayer from '@/components/public/VideoPlayer.vue';
    import TableListBlock from '@/components/public/TableListBlock.vue';
    import TableListLine from '@/components/public/TableListLine.vue';
    import PearlHistory from '@/components/public/PearlHistory.vue';
    import {useLayer} from "@/assets/js/useLayer.js"
    import Chat from '@/components/public/Chat.vue';
    import { fullScreen,guid } from '@/assets/js/common.js';
    import { INTERFACE,USER_TYPE } from "@/assets/js/const.js"
    import { nextTick } from '@vue/runtime-core';
    export default{
        components:{
            VideoPlayer,
            TableListBlock,
            TableListLine,
            PearlHistory,
            Chat
        },
        data() {
            return {
                screen:"全屏",
                video1: "全景1",
                video2: "全景2",
                notice:"",//公告
                limitred:{
                    z:"0-0",
                    x:"0-0",
                    h:"0-0",
                    zd:"0-0",
                    xd:"0-0"
                },//限红
                tableArr:this.$route.params.tableArr?JSON.parse(this.$route.params.tableArr).tableArr:"",//台面列表
                tableInfo:this.$route.params.tableInfo?JSON.parse(this.$route.params.tableInfo):"",//台面信息
                isShowBlock:false,//是否显示块状台面列表
                countDown:0,//倒计时
                timer:null,//倒计时计时器对象
                videoUrlOne:"",//第一个视频的地址
                videoUrlTwo:"",//第二个视频的地址
                audioSrc:"",
                isShowQuestion:false,//是否显示问题反馈
                questionTitle:"",//问题反馈标题
                questionContent:"",//问题反馈内容
                isLoadChat:false,//是否已点击过客服组件
                isShowChat:false,//是否显示联系客服
                chatWs:null,//聊天websocket对象
                stateInfo:"",//当前台面状态信息
            }
        },
        beforeCreate(){
            if(this.$root.ws==null||this.$root.ws.readyState!=1){
                this.$router.push("/");
            }
        },
        mounted(){
            if(this.$root.ws){
                console.log(this.$root.currentLine.id);
                this.$root.ws.wsSend("all_getvideo",this.tableInfo.id, this.$root.currentLine.id, this.$root.user.name, this.$root.user.guid);
                this.$root.addMessage("table",(data) => {
                    switch (data.command) {
                        case "all_getnotice"://获取公告
                            this.notice=data.str;
                            break;
                        case "bac_gettablelimitred"://获取限红
                            this.limitred=data.limitred;
                            this.stateInfo=this.tableInfo.game=="1"?"百家":"龙虎";
                            break;
                        case "all_entertable"://进入台面
                            if(data.result==1){
                                this.tableInfo={
                                    boot:data.boot,
                                    innings:data.innings,
                                    room:data.room,
                                    table:data.table,
                                    id:data.id,
                                    game:this.tableInfo.game
                                }
                                console.log('切换台面')
                                this.$root.ws.wsSend("all_getvideo",this.tableInfo.id, this.$root.currentLine.id, this.$root.user.name, this.$root.user.guid);
                                this.$root.ws.wsSend("all_getcountdown",1,data.id,this.$root.user.name,this.$root.user.guid);
                                this.$root.ws.wsSend("bac_gettablelimitred",this.$root.user.name,data.id,1,this.$root.user.guid);
                            }
                            break;
                        case "all_getcountdown"://获取倒计时
                            if(data.id==this.tableInfo.id){
                                this.setCountDown(data.countdown);
                            }
                            break;
                        case "all_startgame"://接收游戏开局
                            if(data.id==this.tableInfo.id){
                                this.audioSrc=require('@/assets/media/common/bet_start_1.mp3');
                                this.$nextTick(()=>{
                                    document.getElementById("audioId").play();
                                })
                                this.setCountDown(data.countdown);
                                this.tableInfo={
                                    boot:data.boot,
                                    innings:data.innings,
                                    room:this.tableInfo.room,
                                    table:this.tableInfo.table,
                                    id:data.id,
                                    game:this.tableInfo.game
                                }
                                this.stateInfo="start";
                            }
                            break;
                        case "bac_gameresult"://接收游戏结果
                            if(data.id==this.tableInfo.id){
                                var Z=['D', 'F', 'E', 'A'];
                                var X=['H', 'G', 'I', 'B'];
                                var H=['L', 'J', 'K', 'O', 'C'];
                                var ZD=['D', 'L', 'G', 'I', 'F', 'J'];
                                var XD=['H', 'L', 'I', 'F', 'K', 'E'];
                                var L=['M'];
                                var HU=['N'];
                                if(Z.indexOf(data.pearl.substr(0, 1)) != -1){//庄赢
                                    this.audioSrc=require('@/assets/media/baccarat/win_z.mp3');
                                    this.$nextTick(()=>{
                                        document.getElementById("audioId").play();
                                    })
                                    this.stateInfo="winZhuang";
                                }else if(X.indexOf(data.pearl.substr(0, 1)) != -1){//闲赢
                                    this.audioSrc=require('@/assets/media/baccarat/win_x.mp3');
                                    this.$nextTick(()=>{
                                        document.getElementById("audioId").play();
                                    })
                                    this.stateInfo="winXian";
                                }else if(H.indexOf(data.pearl.substr(0, 1)) != -1){//和局
                                    this.audioSrc=require('@/assets/media/baccarat/win_draw.mp3');
                                    this.$nextTick(()=>{
                                        document.getElementById("audioId").play();
                                    })
                                    this.stateInfo="winHe";
                                }else if(L.indexOf(data.pearl.substr(0, 1)) != -1){//龙赢
                                    this.audioSrc=require('@/assets/media/baccarat/win_l.mp3');
                                    this.$nextTick(()=>{
                                        document.getElementById("audioId").play();
                                    })
                                    this.stateInfo="winLong";
                                }else if(HU.indexOf(data.pearl.substr(0, 1)) != -1){//虎赢
                                    this.audioSrc=require('@/assets/media/baccarat/win_h.mp3');
                                    this.$nextTick(()=>{
                                        document.getElementById("audioId").play();
                                    })
                                    this.stateInfo="winHu";
                                }
                            }
                            break;
                        case "all_endgame"://接收到游戏结束
                            if(data.id==this.tableInfo.id){
                                this.audioSrc=require("@/assets/media/common/bet_stop.mp3");
                                this.$nextTick(()=>{
                                    document.getElementById("audioId").play();
                                })
                                this.stateInfo="end";
                            }
                            break;
                        case "all_logout"://注销用户
                            if(data.result==1){
                                this.$root.delMessage("table","tableListBlock","tableListLine","pearlHistory","videoPlayervideo1","videoPlayervideo2");
                                this.$router.push("/");
                            }
                            break;
                        case "all_getvideo"://获取视频
                            this.videoUrlOne="";
                            this.videoUrlTwo="";
                            this.$nextTick(()=>{
                                this.videoUrlOne=data.flvline+'/'+data.video[0];
                                this.videoUrlTwo=data.flvline+'/'+data.video[1];
                                
                                console.log('jjjjjjjjjjj'+this.videoUrlOne)
                            })
                            break;
                        case "all_addquestion"://问题反馈
                            if(data.result==1){
                                useLayer({str:"提交成功，感谢您的反馈"});
                                this.questionTitle="";
                                this.questionContent="";
                                this.isShowQuestion=false;
                            }
                            break;
                        default:
                            break;
                    }
                })
                this.$root.ws.wsSend("all_getnotice",1,this.$root.user.name,this.$root.user.guid);
                this.$root.ws.wsSend("bac_gettablelimitred",this.$root.user.name,this.tableInfo.id,1,this.$root.user.guid);
                this.$root.ws.wsSend("all_getcountdown",1,this.tableInfo.id,this.$root.user.name,this.$root.user.guid);
            }
        },
        methods: {
            /**
             * 绑定点击返回大厅按钮
             */
            goLobby() {
                this.$root.delMessage("table","tableListBlock","tableListLine","pearlHistory","videoPlayervideo1","videoPlayervideo2");
                this.$router.push("/index");
            },
            /**
             * 绑定点击全景按钮
             */
            btnScreenClick(){
                fullScreen();
            },
            /**
             * 绑定点击视频1按钮
             */
            btnVideo1Click(){
                this.video1=this.video1=="全景1"?"现场1":"全景1";
            },
            btnVideo2Click(){
                this.video2=this.video2=="全景2"?"现场2":"全景2";
            },
            showTableBlock(){
                this.isShowBlock = this.isShowBlock==true?false:true;
            },
            setCountDown(countDown){
                this.timer&&clearInterval(this.timer);
                this.countDown=countDown;
                var _this=this;
                this.timer = setInterval(function setTime(){
                    if(_this.countDown==11){
                        _this.audioSrc=require("@/assets/media/common/time_ten_1.mp3");
                        _this.$nextTick(()=>{
                            document.getElementById("audioId").play();
                        })
                        _this.stateInfo="tenSecond";
                    }
                    if(_this.countDown==0){
                        clearInterval(_this.timer);
                    }else{
                        _this.countDown--;
                    }
                    return setTime;
                }(),1000)
            },
            changeGame(game){
                this.tableInfo.game=game;
            },
            exitGame(){
                this.$root.ws.wsSend('all_logout', this.$root.user.name, this.$root.user.guid);
            },
            subQuestion(){
                this.$root.ws.wsSend("all_addquestion",this.questionTitle,this.questionContent,1,this.$root.user.name, this.$root.user.guid);
            },
            msgWebsocket(onmessage){
                if(this.chatWs){
                    let invalid="";//无效字符串
                    this.chatWs.onmessage=async (e)=>{
                        console.log("接收："+e.data);
                        
                        //数据包处理
                        let arr=[];//存放接收到的数据
                        let str=e.data;
                        try {
                            let obj1 = JSON.parse(str);//尝试转换接收到的数据
                            arr.push(obj1);//成功则加入数组作为一条有效数据
                        } catch (error) {
                            while (str.indexOf("}{")!=-1) {//连包
                                console.log("有连包");
                                let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                                str = str.replace(part,"");
                                try {
                                    let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                                    arr.push(obj2);//成功则加入数组作为一条有效数据
                                } catch (error) {//失败说明是不完整数据
                                    invalid+=part;//与上一次的无效数据拼一起
                                    try {
                                        let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                                        arr.push(obj3);//成功则加入数组作为一条有效数据
                                        invalid="";//并且清空无效字符串
                                    } catch (error) {}//不成功不理会
                                }
                            }
                            //走到这里说明没有连包了
                            try {
                                let obj4=JSON.parse(str);//尝试转换剩余的片段
                                arr.push(obj4);//成功则加入数组作为一条有效数据
                            } catch (error) {//失败则说明是不完整数据
                                console.log("有分包");
                                invalid+=str;//与上一次的无效数据拼一起
                                try {
                                    let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                                    arr.push(obj5);//成功则加入数组作为一条有效数据
                                    invalid="";//并且清空无效字符串
                                } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
                            }
                        }
        
                        //接口响应
                        for (let i = 0; i < arr.length; i++){
                            let data = arr[i];
                            console.log("接收【转换】："+JSON.stringify(data));
                            // switch (data["fun"]) {
                                
                            // }
                            onmessage&&onmessage(data);
                            await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
                        }
        
                    }
                }
            },
            sendWebsocket(obj){
                if(this.chatWs){
                    console.log("发送："+JSON.stringify(obj));
                    this.chatWs.send(JSON.stringify(obj));
                }
            },
        },
        watch: {
            isShowChat(newVal) {
                if(newVal){//点击联系客服时
                    if(!this.chatWs){
                        this.chatWs=new WebSocket(config().ws);
			            this.chatWs.onopen=()=>{
                            let id=this.$root.user.guid;
                            let name=this.$root.user.name;
                            let g=guid();
                            let type=USER_TYPE.client;
                            history.pushState({
                                id,name,type,guid:g
                            },"")
                            this.isLoadChat=true;
                            this.chatWs.send(JSON.stringify({
                                fun:INTERFACE.login,
                                id,
                                pwd:"",
                                name,
                                type,
                                guid:g
                            }))
                        };
                    }
                }
            },
        },
    }
</script>