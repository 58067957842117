/**
 * 绘制验证码
 * @param {Element} canvas 画布dom对象
 */
function drawCode(canvas) {
	var canvas_width=canvas.offsetWidth*2;
	var canvas_height=canvas.offsetHeight*2;
	var context = canvas.getContext("2d");
	canvas.width = canvas_width;
	canvas.height = canvas_height;
	var sCode = "1,2,3,4,5,6,7,8,9,0";
	var aCode = sCode.split(",");
	var aLength = aCode.length;//获取到数组的长度
	var str="";//生成的验证码
	for (var i = 0; i <= 3; i++) {
        
		var j = Math.floor(Math.random() * aLength);//获取到随机的索引值
		var deg = Math.random() * 30 * Math.PI / 180;//产生0~30之间的随机弧度
		var txt = aCode[j];//得到随机的一个内容
		str += txt.toLowerCase();
		//var x = 10 + i * 20;//文字在canvas上的x坐标
		//var y = 20 + Math.random() * 8;//文字在canvas上的y坐标
		var x = i * (canvas_width/4.5);//文字在canvas上的x坐标
		var y = (canvas_height/1.5) + Math.random() * 8;//文字在canvas上的y坐标
		context.font = "bold "+canvas_width/2.5+"px 微软雅黑";

		context.translate(x, y);
		context.rotate(deg);

		context.fillStyle = randomColor();
		context.fillText(txt, 0, 0);

		context.rotate(-deg);
		context.translate(-x, -y);
	}
	for (var i = 0; i <= 5; i++) { //验证码上显示线条
		context.strokeStyle = randomColor();
		context.beginPath();
		context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
		context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
		context.stroke();
	}
	for (var i = 0; i <= 30; i++) { //验证码上显示小点
		context.strokeStyle = randomColor();
		context.beginPath();
		var x = Math.random() * canvas_width;
		var y = Math.random() * canvas_height;
		context.moveTo(x, y);
		context.lineTo(x + 1, y + 1);
		context.stroke();
    }
    return str;
}
/**
 * 得到随机的颜色值
 */
function randomColor() {
	var r = Math.floor(Math.random() * 256);
	var g = Math.floor(Math.random() * 256*0);
	var b = Math.floor(Math.random() * 256);
	return "rgb(" + r + "," + g + "," + b + ")";
}
/**
 * 绘制canvas表格
 * @param {Element} canvasArr 
 * @param {Number} column 
 * @param {Number} row 
 */
function drawCanvas(canvasArr,column,row) {
	var cellHeight,cellWidth,width,height;
	for (let i = 0; i < canvasArr.length; i++) {
		width=canvasArr[i].width;
		height=canvasArr[i].height;
		cellHeight=height/row;
		cellWidth=width/column;
		var ctx = canvasArr[i].getContext('2d');
		ctx.clearRect(0, 0, width, height);
		ctx.strokeStyle = '#000';
		ctx.lineWidth = 1;
		//绘制表格横线
		for (let i = 0; i < row; i++) {
			ctx.beginPath();
			ctx.moveTo(0, Math.floor(cellHeight * i)+0.5);
			ctx.lineTo(cellWidth * column, Math.floor(cellHeight * i)+0.5);
			ctx.stroke();
		}
		//绘制表格竖线
		for (let i = 0; i < column; i++) {
			ctx.beginPath();
			ctx.moveTo(Math.floor(cellWidth * i)+0.5, 0);
			ctx.lineTo(Math.floor(cellWidth * i)+0.5, height);
			ctx.stroke();
		}
	}
}
/**
 * webSocket发送命令
 */
WebSocket.prototype.wsSend=function(){
	var str = '';
	for (let i = 0; i < arguments.length; i++) {
		i != arguments.length - 1 && (str += arguments[i] + '|');
		i == arguments.length - 1 && (str += arguments[i] + ';');
	}
	console.log('发送命令：' + str);
	this.send(str);
}
/**
 * 获取当前浏览设备类型(PC端：h5_web 移动端：h5_phone)
 */
function getDeviceType() {
	if ((navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		))) {
		return 'h5_phone';
	} else {
		return 'h5_web';
	}
}
/**
 * 填充台面路珠
 * @param {Element} elem 要填充的画布DOM对象
 * @param {Number} row 行数
 * @param {Number} column 列数
 * @param {Array} pearlArr 路珠数组列表
 */
function fillRoomPearl(elem,row,column,pearlArr) {
	if(elem){
		let style=getComputedStyle(elem);
		var ctx=elem.getContext("2d");
		var cellHeight=elem.height/row;
		var cellWidth=elem.width/column;
		ctx.textBaseline = 'middle';
		ctx.font = "bold "+cellWidth * .6 + 'px SimHei';
        var maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
        var lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
        var currentRow;//保存当前列已达到的行数
        for (var i = 0; i < pearlArr.length; i++) {
			for (var j = 0, currentRow = 0; j < pearlArr[i].length; j++) {//currentRow为当前列的露珠已到达的行数
				ctx.lineWidth = cellWidth * .12;//边框粗细
                //设置圆圈颜色
                var letter = pearlArr[i][j].substr(0, 1);//获取首字母
                if (letter == 'H' || letter == 'N' || letter == 'G' || letter == 'I' || letter == 'B') {
                    ctx.fillStyle = ctx.strokeStyle = "#0000cc";//填充颜色
                } else if (letter == 'D' || letter == 'M' || letter == 'F' || letter == 'E' || letter == 'A') {
					ctx.fillStyle = ctx.strokeStyle = "#cc0000";
                } else if (letter == 'L' || letter == 'N' || letter == 'J' || letter == 'K' || letter == 'O' || letter == 'C') {
					ctx.fillStyle = ctx.strokeStyle = "#009900";
				}
                //设置圆圈位置
                var x = i * cellWidth + (cellWidth / 2);//正常向下的x值
                var y = j * cellHeight + (cellHeight / 2);//正常向下的y值
                if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
                    if (currentRow == 6 && lastPoint < x - (pearlArr[i].length - 6) * cellWidth) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
                        x = (i - ((j + 1) - currentRow)) * cellWidth + (cellWidth / 2);//当前列数-
                    } else {//否则右拐
                        x = (i + j - currentRow + 1) * cellWidth + (cellWidth / 2);//当前个数-当前所在行+当前列=要画的露珠列
                        maxPoint[currentRow - 1] = x;//更新最大坐标点
                    }
                    y = (currentRow - 1) * cellHeight + (cellHeight / 2);
                } else {//否则向下画
                    currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -2 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
                    maxPoint[currentRow] = x;//更新最大坐标点
                    currentRow++;//累加当前列露珠到达的行数
                }
                //绘制圆圈
				ctx.beginPath();
                ctx.arc(x, y, cellWidth / 3.2, 0, 2 * Math.PI);
				ctx.stroke();
                //绘制斜线和数字
                if (pearlArr[i][j].length == 3) {//当前遍历到的最后一项并且字符串长度为3
                    var text = pearlArr[i][j].substr(2, 1);
                    ctx.fillText(text, x - ctx.measureText(text).width / 2, y);
                    ctx.lineWidth = cellWidth * .08;//边框粗细
                    ctx.moveTo(x, y + cellHeight / 2.5);//向左拐
                    ctx.lineTo(x + cellHeight / 2.5, y);
                    ctx.stroke();
                }
                //填充圆点
                if (letter == 'D' || letter == 'L' || letter == 'G' || letter == 'I' || letter == 'F' || letter == 'J') {//含有庄对
                    ctx.fillStyle = "#cc0000";
                    ctx.beginPath();
                    ctx.arc(x - cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / 3.2 / 3.5, 0, 2 * Math.PI);
                    ctx.fill();
                }
                if (letter == 'H' || letter == 'L' || letter == 'I' || letter == 'F' || letter == 'K' || letter == 'E') {//含有闲对
                    ctx.fillStyle = "#0000cc";
                    ctx.beginPath();
                    ctx.arc(x + cellWidth / 3.5, y - cellHeight / 3.5, cellWidth / 3.2 / 3.5, 0, 2 * Math.PI);
                    ctx.fill();
                }
            }
        }
	}
}
/**
 * 移除路珠列表中的“和”
 * @param {Array} arr 要处理的数组
 * @param {Number} column 要保留的列数
 * @param {Boolean} isHold 末列的和是否保留
 */
function delPearlTie(arr,column,isHold) {
	var arrZ = ['D', 'M', 'F', 'E', 'A'];
	var arrX = ['H', 'N', 'G', 'I', 'B'];
	var arr1 = [];//外层数组
	var letter = '';//首字母
	var count = 0;//和计数器
	var arrCount = 0;//传入数组总局数
	for (var i = 0; i < arr.length; i++) {
		arrCount += arr[i].length;
		if (arr1.length < column) {//未达到需要的列数
			letter = arr[i][0].substr(0, 1);//取内层数组第一项首字母
			if (letter == 'L' || letter == 'J' || letter == 'K' || letter == 'O' || letter == 'C') {//是和
				//if ((arr1.length == column - 1 || i == arr.length - 1) && isHold) {//是‘和’的前提下并且差一列达到所要列数或者已经遍历到数组最后一项，并且为需要显示
				if (i == arr.length - 1 && isHold) {//如果是和的前提下并且已经遍历到最后一项
					arr1[arr1.length] = [];
					arr1[arr1.length - 1].push(arr[i][0]);//只压入第一项和
					arr[i].length - 1 != 0 && (arr1[arr1.length - 1][0] = arr1[arr1.length - 1][0] + (arr[i].length - 1));
				} else {//不是末列
					count = arr[i].length;
					continue;
				}
			} else {//不是和
				if (count != 0) {//有统计了和
					var arr2 = [];
					for (var j = 0; j < arr[i].length - 1; j++) {//遍历并添加除了最后一项
						arr2.push(arr[i][j]);
					}
					arr2.push(arr[i][arr[i].length - 1] + count);//将统计值拼接在当前遍历到的数组的最后一项
					if (
						arr1.length > 0 && (
						(arrZ.indexOf(arr[i][0].substr(0, 1)) != -1 && arrZ.indexOf(arr1[arr1.length - 1][0].substr(0, 1)) != -1) ||
						(arrX.indexOf(arr[i][0].substr(0, 1)) != -1 && arrX.indexOf(arr1[arr1.length - 1][0].substr(0, 1)) != -1)
						)) {//如果当前项之前已有项并且当前项与‘和’之前的项一样为庄类或者一样为闲类，则合并到一起
						var delArr = arr1.splice(arr1.length - 1, 1);//删掉原有的拼接好的数组中的最后一项
						arr2 = arr2.concat(delArr[0]);//并拼接到当前项的最后
					}
					arr1.push(arr2);
					count = 0;
				} else {//还没有统计和
					arr1.push(arr[i].slice());
				}
			}
		} else {
			break;
		}
	}
	//再次循环用于验证是否需要因右拐超出画布而移除最前面部分列
	var maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
	var lastPoint = 0;
	for (var i = arr1.length - 1; i >= 0; i--) {//倒序遍历处理后的数组
		for (var j = 0, currentRow = 0; j < arr1[i].length; j++) {
			if (currentRow == 6 || arr1.length - i <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
				if (!(currentRow == 6 && lastPoint < (arr1.length - i) - (arr1[i].length - 6))) {//如果非(当前已到达第6行并且向左拐不与当前行最大点重叠)，则向右拐
					maxPoint[currentRow - 1] = arr1.length - i + (j - 5);//更新最大坐标点为当前个数-当前所在行+当前列=要画的露珠列
				}
			} else {//否则向下画
				currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? 0 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
				maxPoint[currentRow] = arr1.length - i;//更新最大坐标点
				currentRow++;//累加当前列露珠到达的行数
			}
		}
	}
	if (maxPoint[5] > column && maxPoint[5] > maxPoint[0]) {//如果最后一列的长度超出画布可画列数并且最后一列大于第一列
		arr1.splice(arr1.length - 1 - (maxPoint[5] - maxPoint[0]), maxPoint[5] - maxPoint[0]);//去掉多余项
	}
	return arr1;
}
/**
 * 获取IP
 */
function getIP() {
	try {
		return returnCitySN.cip;
	} catch (e) {
		return '127.0.0.1';
	}
}
/**
 * 全屏
 */
function fullScreen() {
	var element = document.documentElement;
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
}
/**
 * 生成GUID
 */
function guid() {
    var str = '';
    var i = 32;
    while (i--) {
        str += Math.floor(Math.random() * 16.0).toString(16);
    }
    return str;
}
//获取当前时间
function getDate() {
    let date = new Date();
    let str=
        date.getFullYear() + "-" +
        (date.getMonth() + 1<10?"0"+(date.getMonth()+1):date.getMonth()+1) + "-" +
        (date.getDate()<10?"0"+date.getDate():date.getDate()) + " " +
        (date.getHours()<10?"0"+date.getHours():date.getHours()) + ":" +
        (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes());// + ":" + date.getSeconds();
    return str;
}

export{
	drawCode,
	drawCanvas,
	getDeviceType,
	fillRoomPearl,
	delPearlTie,
	getIP,
	fullScreen,
	guid,getDate
}