<template>
    <div class="bg-black h-100 position-relative">
        <span v-if="callTip!=''" class="position-absolute top-50 start-50 translate-middle text-muted">{{ callTip }}</span>
        <video autoplay :class="[callTip!=''?'d-none':'d-block']" class="w-100 h-100 rounded" :srcObject="remoteStream"></video>
        <div v-if="!isHiddenOther" class="position-absolute img-thumbnail end-0 top-0 mt-2 me-2 bg-black w-25 h-25">
            <video muted autoplay :class="{'invisible':!isVideo}" class="d-block w-100 h-100 rounded fit-fill" :srcObject="localStream"></video>
            <div v-show="!isVideo" class="position-absolute start-0 end-0 top-0 bottom-0 p-1">
                <div class="d-flex flex-column justify-content-center align-items-center bg-secondary bg-opacity-50 rounded h-100">
                    <i class="bi bi-camera-video-off-fill d-flex display-1"></i>
                    <div class="fs-6">{{videoTip}}</div>
                </div>
            </div>
        </div>
        <div v-if="!isHiddenOther" class="position-absolute d-flex justify-content-center fs-2 w-100 bottom-0 mb-4">
            <div v-if="isShowMediaBtn" :class="[isAudio?'border-info':'bg-secondary bg-opacity-50 opacity-50']" class="text-white rounded-circle border border-2 p-2 mx-3" @click="btnOperate(operate.audio)" role="button">
                <i :class="[isAudio?'bi-mic-fill text-info':'bi-mic-mute-fill']" class="bi d-flex"></i>
            </div>
            <div v-if="isShowMediaBtn" :class="[isVideo?'border-info':'bg-secondary bg-opacity-50 opacity-50']" class="text-white rounded-circle border border-2 p-2 mx-3" @click="btnOperate(operate.video)" role="button">
                <i :class="[isVideo?'bi-camera-video-fill text-info':'bi-camera-video-off-fill']" class="bi d-flex"></i>
            </div>
            <div class="text-white rounded-circle border border-2 border-danger p-2 mx-3 bg-danger" @click="btnOperate(operate.close)" role="button">
                <i class="bi bi-telephone-fill d-flex" style="transform:rotateZ(135deg)"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { IS_DEBUG } from "@/assets/js/const.js";
import { useLayer } from "@/assets/js/useLayer.js"
import { nextTick } from '@vue/runtime-core';
export default {
    props: {
        isHiddenOther:false,//是否隐藏其他元素
        localId:null,//本地id
        peerId:null//对等方id
    },
    emits: ['closeChat','chatMsgWebsocket'],
    data() {
        return {
            isAudio: true,//是否正在语音
            isVideo: false,//是否正在视频
            localStream:null,//本地流
            remoteStream:null,//远端流
            //操作类型
            operate:{
                video:1,
                audio:2,
                close:3
            },
            callTip:"等待对方进入通话",//呼叫提示文本
            videoTip:"媒体获取中",//摄像头提示文本
            isShowMediaBtn:false,//是否显示操作媒体的按钮
            peerConn:null,//对等连接对象
            makingOffer:false,//当前是否正在处理提议，防止冲突
            signaler:null,//信令对象
            polite:false,//当前本地端是否礼貌，默认为不礼貌
        };
    },
    unmounted() {
        
    },
    async mounted() {
        IS_DEBUG&&console.log("本地ID："+this.localId);
        IS_DEBUG&&console.log("远端ID："+this.peerId);
        //WebRTC完美协商模式
        
        //创建对等连接
        this.callTip="正在创建对等连接";
        this.peerConn=new RTCPeerConnection({ 'iceServers': config().iceServers });
        console.log(this.peerConn.signalingState);
        IS_DEBUG&&console.log("创建对等连接");

        //获取本地媒体
        this.callTip="获取本地媒体";
        let stream = await this.getUserMedia({
            audio:this.isAudio,
            video:this.isVideo
        });
        this.localStream = stream;//绑定本地媒体到video标签中
        this.isShowMediaBtn=true;//显示语音和视频的操作按钮
        (!this.isVideo)&&(this.videoTip="未开启");//变更本地媒体状态提示
        IS_DEBUG&&console.log("获取本地媒体成功！");
        this.callTip="等待对方进入通话";

        //连接websocket信令服务器
        this.signaler = await this.connSignaling(this.peerConn);
        
        //当需要通过信令通道协商连接时，既发生在连接的初始设置期间（即媒体首次添加到对等连接对象中时），也发生在通信环境发生变化需要重新配置连接的任何时候。
        this.peerConn.onnegotiationneeded=async ()=>{
            try {
                this.makingOffer=true;
                //将提议设置为本地描述
                await this.peerConn.setLocalDescription();
                IS_DEBUG&&console.log("设置本地描述，发送description信令通知对方");
                this.signaler.send(JSON.stringify({
                    fun:"signaler",
                    msg: JSON.stringify(this.peerConn.localDescription),
                    from:this.localId,
                    to:this.peerId,
                    type:"description"
                }));
            } catch (error) {
                console.error(error);
            }finally {
                this.makingOffer = false;
            }
        }

        //更改ice候选人时
        this.peerConn.onicecandidate = ({ candidate }) => {
            //发送信令
            console.log("更改ice候选人，发送candidate信令通知对方，candidate.type为"+(candidate?candidate.type:candidate));
            this.signaler.send(JSON.stringify({
                fun:"signaler",
                msg: JSON.stringify(candidate),
                from:this.localId,
                to:this.peerId,
                type:"candidate"
            }));
        };

        //ice连接状态改变
        this.peerConn.oniceconnectionstatechange=()=>{
            console.log("ice连接状态改变为："+this.peerConn.iceConnectionState);
            if (this.peerConn.iceConnectionState === "failed") {
                this.peerConn.restartIce();
                IS_DEBUG&&console.log("重启ICE");
            }else if(this.peerConn.iceConnectionState === "connected"){
                if((!this.peerConn.getRemoteStreams()[0])||this.peerConn.getRemoteStreams()[0].getVideoTracks().length==0){
                    this.callTip="对方关闭了摄像头";
                }else{
                    this.callTip="";
                }
                useLayer({str:"已接通"});
            }else if(this.peerConn.iceConnectionState === "checking"){
                this.callTip="正在连接中";
            }else if(this.peerConn.iceConnectionState=="disconnected"){
                this.callTip="连接失败";
                this.peerConn.restartIce();
                IS_DEBUG&&console.log("重启ICE");
            }
        }

        //远端添加轨道
        this.peerConn.ontrack=({track,streams})=>{
            IS_DEBUG&&console.log("远端添加媒体轨道");
            console.log(track);
            console.log(streams);
            console.log(streams[0].getTracks());
            track.onunmute=()=>{
                // if(!this.remoteStream){
                //     this.remoteStream=new MediaStream();
                // }
                // this.remoteStream.addTrack(track);
                console.log(streams);
                this.remoteStream=streams[0];
                this.callTip="";
                IS_DEBUG&&console.log("显示远端视频");
            }
        }

        this.peerConn.onsignalingstatechange=(event)=>{
            console.log("信令状态改变为："+this.peerConn.signalingState);
        }
        this.peerConn.onicegatheringstatechange=(event)=>{
            console.log(" ICE 候选人收集过程状态改变为："+this.peerConn.signalingState);
        }
        this.peerConn.onconnectionstatechange=(event)=>{
            console.log("会话连接状态改变为："+this.peerConn.connectionState);
            if(this.peerConn.iceConnectionState=="disconnected"){
                useLayer({str:"对方已挂断"});
                this.$emit('closeChat');
            }
        }
    },
    methods: {
        /**
         * 获取用户媒体设备
         */
        getUserMedia(obj){
            if(navigator.mediaDevices.getUserMedia){
                //最新标准API
                return navigator.mediaDevices.getUserMedia(obj);
            } else if (navigator.webkitGetUserMedia){
                //webkit内核浏览器
                return navigator.webkitGetUserMedia(obj);
            } else if(navigator.mozGetUserMedia){
                //Firefox浏览器
                return navagator.mozGetUserMedia(obj);
            } else if (navigator.getUserMedia){
                //旧版API
                return navigator.getUserMedia(obj);
            }
        },
        /**
         * 连接信令服务器
         */
        connSignaling(){
            return new Promise((resolve)=>{

                let ignoreOffer = false;//是否需要忽视提议
                let ws=null;
                this.callTip="正在连接信令服务器";
                try {
                    ws=new WebSocket(config().ws.substring(0,config().ws.indexOf('?')==-1?config().ws.length:config().ws.indexOf('?'))+"?id="+this.localId);
                } catch (error) {
                    this.callTip="连接信令服务器失败";
                }
                let invalid="";//无效字符串
                ws.onmessage=async (e)=>{
                    IS_DEBUG&&console.log("接收："+e.data);
                    let obj = this.setSocketData(e.data,invalid);
                    let arr=obj.arr;
                    invalid=obj.invalid;
                    //接口响应
                    for (let i = 0; i < arr.length; i++){
                        let data = arr[i];
                        // IS_DEBUG&&console.log("接收【转换】："+JSON.stringify(data));
                        switch (data["fun"]) {
                            case "signaler":
                                if(data["msg"]){
                                    let obj = JSON.parse(data["msg"]);
                                    try {
                                        //description
                                        if (obj&&obj.type) {//要么是提议，要么是对方发送的答复。
                                            console.log("对方发送提议或者答复"+obj.type);
                                            console.log(this.polite,obj.type,this.makingOffer,this.peerConn.signalingState)
                                            const offerCollision =
                                                obj.type === "offer" &&
                                                (this.makingOffer||this.peerConn.signalingState!=="stable");//提议是否冲突（当前类型为提议并且（当前正在处理提议或者信令状态不为稳定状态））

                                            ignoreOffer = !this.polite && offerCollision;//是否需要忽视提议
                                            console.log(offerCollision);
                                            if (ignoreOffer) {
                                                return;
                                            }
                                            
                                            await this.peerConn.setRemoteDescription(obj);//提议或者答复
                                            IS_DEBUG&&console.log("设置远程描述");
                                            if (obj.type === "offer") {//如果是提议
                                                await this.peerConn.setLocalDescription();
                                                //发送信令
                                                ws.send(JSON.stringify({
                                                    fun:"signaler",
                                                    msg: JSON.stringify(this.peerConn.localDescription),
                                                    from:this.localId,
                                                    to:this.peerId,
                                                    type:"description"
                                                }));
                                                IS_DEBUG&&console.log("设置本地描述，发送description信令通知对方");

                                                //远端调用removeTrack移除轨道出触发协商
                                                this.peerConn.getRemoteStreams()[0]&&console.log(this.peerConn.getRemoteStreams()[0].getVideoTracks())
                                                if((!this.peerConn.getRemoteStreams()[0])||this.peerConn.getRemoteStreams()[0].getVideoTracks().length==0){
                                                    this.callTip="对方关闭了摄像头";
                                                }

                                            }
                                        }else if (obj&&obj.candidate) {//它是作为trickle ICE的一部分从远程对等方接收到的 ICE 候选者。候选人注定要通过将其传递到本地 ICE 层addIceCandidate()。
                                            try {
                                                await this.peerConn.addIceCandidate(obj);
                                            } catch (err) {
                                                if (!ignoreOffer) {
                                                    throw err;
                                                }
                                            }
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }else if(data["result"]==0&&data["type"]=="state"){//找不到接收方，说明对方还没有连接到信令服务器
                                    console.log("对方未在线，设置本地为礼让方");
                                    this.polite=true;//将本地设置为礼貌方
                                }else if(data["result"]==1&&data["type"]=="state"){
                                    //对方在线则添加媒体轨道到对等连接中
                                    //为对等连接添加媒体轨道
                                    console.log("对方在线，本地为不礼让方");
                                    this.callTip="添加媒体轨道";
                                    this.localStream.getTracks().forEach(track=>this.peerConn.addTrack(track,this.localStream));
                                    IS_DEBUG&&console.log("将本地媒体轨道添加到对等连接中");
                                }
                                break;
                        }
                        await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
                    }
                }
                ws.onopen=()=>{
                    IS_DEBUG&&console.log("连接websocket信令服务器成功！");
                    //看对方是否连接了信令服务器
                    console.log("发送信令看对方是否已连接信令服务器")
                    ws.send(JSON.stringify({
                        fun:"signaler",
                        msg: JSON.stringify({state:"connecting"}),
                        from:this.localId,
                        to:this.peerId,
                        type:"state"
                    }));
                    //心跳
                    setInterval(() => {
                        ws.send(JSON.stringify({
                            fun:"online",
                            id:"",
                            type:""
                        }));
                    }, 8000);
                    resolve(ws);
                }
                // return ws;

            })
        },
        /**
         * 处理websocket接收的数据
         */
        setSocketData(str,invalid){
            //数据包处理
            let arr=[];//存放接收到的数据
            try {
                let obj1 = JSON.parse(str);//尝试转换接收到的数据
                arr.push(obj1);//成功则加入数组作为一条有效数据
            } catch (error) {
                while (str.indexOf("}{")!=-1) {//连包
                    IS_DEBUG&&console.log("有连包");
                    let part=str.substring(0,str.indexOf("}{")+1);//截取背向花括号前的片段
                    str = str.replace(part,"");
                    try {
                        let obj2 =JSON.parse(part);//尝试转换截取出来的片段
                        arr.push(obj2);//成功则加入数组作为一条有效数据
                    } catch (error) {//失败说明是不完整数据
                        invalid+=part;//与上一次的无效数据拼一起
                        try {
                            let obj3=JSON.parse(invalid);//尝试转换拼接后的字符串
                            arr.push(obj3);//成功则加入数组作为一条有效数据
                            invalid="";//并且清空无效字符串
                        } catch (error) {}//不成功不理会
                    }
                }
                //走到这里说明没有连包了
                try {
                    let obj4=JSON.parse(str);//尝试转换剩余的片段
                    arr.push(obj4);//成功则加入数组作为一条有效数据
                } catch (error) {//失败则说明是不完整数据
                    IS_DEBUG&&console.log("有分包");
                    invalid+=str;//与上一次的无效数据拼一起
                    try {
                        let obj5=JSON.parse(invalid);//尝试转换拼接后的字符串
                        arr.push(obj5);//成功则加入数组作为一条有效数据
                        invalid="";//并且清空无效字符串
                    } catch (error) {}//不成功则不理会，等待下一次接收数据再处理就好
                }
            }
            return {arr,invalid};
        },
        /**
         * 按钮功能操纵
         * @param {Number} type 功能类型
         */
        btnOperate(type) {
            if(type==this.operate.audio){//操作语音
                let audioTrack = this.localStream.getAudioTracks();//获取媒体流中的音频流轨道
                if(this.isAudio){//当前是开启状态

                    //关闭音频
                    IS_DEBUG&&console.log("关闭音频");
                    console.log(this.peerConn.getSenders())
                    this.peerConn.getSenders().forEach(sender=>sender.track&&sender.track.kind=="audio"&&this.peerConn.removeTrack(sender));//在对等连接中移除音频轨道，以触发协商
                    audioTrack.forEach(async track=>{
                        track.stop();//停止掉轨道，否则设备会依然占用
                        this.localStream.removeTrack(track);//移除轨道
                    });
                    console.log(this.peerConn.getSenders())
                    this.isAudio=false;
                    useLayer({str:"麦克风已关闭"});

                }else{//当前是关闭状态

                    if(audioTrack.length==0){//当前没有音频轨道
                        this.getUserMedia({
                            audio:true
                        }).then((stream)=>{
                            this.isAudio=true;
                            useLayer({str:"麦克风已开启"});
                            stream.getAudioTracks().forEach(track=>{
                                this.localStream.addTrack(track);
                                this.peerConn.addTrack(track,this.localStream);
                            });
                        }).catch((err)=>{
                            
                        })
                    }

                }
            }else if(type==this.operate.video){//操作视频
                
                let videoTrack = this.localStream.getVideoTracks();//获取媒体流中的视频流轨道
                if(this.isVideo){//当前是开启状态

                    //关闭视频
                    IS_DEBUG&&console.log("关闭视频");
                    console.log(this.peerConn.getSenders())
                    this.peerConn.getSenders().forEach(sender=>sender.track&&sender.track.kind=="video"&&this.peerConn.removeTrack(sender));//在对等连接中移除视频轨道，以触发协商
                    videoTrack.forEach(async track=>{
                        track.stop();//停止掉轨道，否则设备会依然占用
                        this.localStream.removeTrack(track);//移除轨道
                    });
                    // this.peerConn.getTransceivers()[0].stop();
                    console.log(this.peerConn.getSenders())
                    this.isVideo=false;
                    this.videoTip="未开启";

                }else{//当前是关闭状态

                    //显示视频
                    console.dir(videoTrack)
                    console.dir(this.peerConn.getSenders())
                    if(videoTrack.length==0){//当前没有视频轨道
                        this.getUserMedia({
                            video:true
                        }).then((stream)=>{
                            this.isVideo=true;
                            stream.getVideoTracks().forEach(track=>{
                                // console.dir(this.localStream.getTracks())
                                this.localStream.addTrack(track);
                                console.log(this.localStream.getTracks())
                                console.log(this.peerConn.getSenders())
                                this.peerConn.addTrack(track,this.localStream);
                                console.log(this.localStream.getTracks())
                                // this.peerConn.getSenders()[1].replaceTrack(track)
                                console.log(this.peerConn.getSenders())
                                console.dir(this.peerConn)
                            });
                        }).catch((err)=>{
                            this.videoTip="未授权";
                        })
                    }

                }

            }else if(type==this.operate.close){//挂断
                if(this.peerConn){
                    this.peerConn.ontrack = null;
                    this.peerConn.onremovetrack = null;
                    this.peerConn.onremovestream = null;
                    this.peerConn.onicecandidate = null;
                    this.peerConn.oniceconnectionstatechange = null;
                    this.peerConn.onsignalingstatechange = null;
                    this.peerConn.onicegatheringstatechange = null;
                    this.peerConn.onnegotiationneeded = null;
                    this.peerConn.close();
                    this.peerConn=null;
                }
                useLayer({str:"已挂断"});
                this.$emit('closeChat');
            }
        },
    },
}
</script>