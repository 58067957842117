<style scoped>
    .video-player-frame{
        background-image: linear-gradient(90deg, #9b6803 0%, #fdfbc7 40%,#996701 80%,#f2ebb2 100%);
        color: white;
    }
    .video-block{
        width: 100%;
        height: 100%;
        background: black;
    }
</style>
<template>
    <div class="video-player-frame">
        <div :id="videoId" class="video-block" v-html="videoHtml"></div>
    </div>
</template>
<script>
    import {useLayer} from "@/assets/js/useLayer.js"
    export default {
        props: ["videoId","videoUrl"],
        data() {
            return {
                videoPlayer: {
                    type:null,//播放类型
                    obj:null//播放实例
                },//视频实例对象
                videoHtml:"",//视频html元素
            }
        },
        unmounted(){
            if(this.videoPlayer.type=='nodeplayer'){//释放资源
                this.videoPlayer.obj.stop();
                this.videoPlayer.obj.clearView();
                this.videoPlayer.obj.release();
            }else if(this.videoPlayer.type=="mpegts"){
                this.videoPlayer.obj.destroy();
            }
        },
        methods: {
            initNodePlayer(flv,videoArr) {
                if(this.videoPlayer.type=='nodeplayer'){//释放资源
                    this.videoPlayer.obj.stop();
                    this.videoPlayer.obj.clearView();
                    this.videoPlayer.obj.release();
                }else if(this.videoPlayer.type=="mpegts"){
                    this.videoPlayer.obj.destroy();
                }
                this.videoPlayer={type:null,obj:null};
                if(this.videoUrl.indexOf('undefined')==-1){
                    if (navigator.userAgent.match(
                        /(phone|pad|pod|iPhone|iPod|ios|iPad)/i
                    )){//如果是苹果端
                        this.videoHtml="<canvas id='"+this.videoId+"-canvas' width='" + document.getElementById(this.videoId).clientWidth + "' height='"+ document.getElementById(this.videoId).clientHeight + "'></canvas>";
                        document.querySelectorAll("canvas").forEach((elem,i) => {
                            if(elem.id==this.videoId+"-canvas"){
                                let nodeplayer=new NodePlayer();
                                nodeplayer.setView(this.videoId+"-canvas");
                                nodeplayer.enableAudio(!1);
                                nodeplayer.setBufferTime(100);
                                nodeplayer.setVolume(0.0);
                                nodeplayer.start(this.videoUrl+".flv");
                                this.videoPlayer={
                                    type:'nodeplayer',
                                    obj:nodeplayer
                                }
                            }
                        })
                    }else{
                        if (mpegts.getFeatureList().mseLivePlayback) {
                            this.videoHtml = "<video muted autoplay id='"+this.videoId + '-mpegts'+"' style='width:100%;height:100%'></video>";
                            
                            this.$nextTick(()=>{
                                var player = mpegts.createPlayer({
                                    type: 'flv',  // could also be mpegts, m2ts, flv
                                    isLive: true,
                                    url: this.videoUrl + '.flv',
                                    //url: 'http://192.168.1.20:7001/live/abc123.flv',
                                    hasAudio: false
                                });
                                player.on(mpegts.Events.ERROR, (e)=> {
                                    if (e == mpegts.ErrorTypes.NETWORK_ERROR) {
                                        useLayer({str:"视频源无法播放，请尝试其他线路或台面"});
                                    }
                                })
                                player.attachMediaElement(document.getElementById(this.videoId + '-mpegts'));
                                player.load();
                                player.play().then(() => {
                                    this.videoPlayer={
                                        type:'mpegts',
                                        obj:player
                                    }
                                }).catch(error => {
                                    player.destroy();
                                });
                            })
                        }
                    } 
                }else{
                    useLayer({str:"当前台面没有视频，请尝试其他台面或线路"});
                }
            },
        },
        watch: {
             videoUrl(newVal) {
                console.log(newVal)
                if(newVal!=""){
                    this.initNodePlayer();
                }
            }
        },
    }
</script>